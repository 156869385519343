import { HashLocationStrategy, LocationStrategy } from '@angular/common';
export const environment = {
  production: false,
  base_url: 'https://api-staging-do.strongbee.tech/',
  maps_api: 'AIzaSyCtAUdR-I7xENJ7CoQHURn1Ovhr7S16S58',
  deeplink_scheme: 'stagingstrongbee://',
  tiny_mce_api_key: 'pcscwinjq4bexc7mdtatafbx6sxqwlap16pr1yc417md9mn7',
  optionalModules: [{
    provide: LocationStrategy, 
    useClass: HashLocationStrategy
  }],
  chat_url: "https://cms-strongbee.vercel.app/chat"
};