import { environment } from '../../../environments/environment';

export class Configuration {

  public static get BASE_URL(): string { return environment.base_url; }
  public static get TINY_API_KEY(): string { return environment.tiny_mce_api_key; }
  public static get REGISTRATION(): string { return 'client-registration/submit-client'; }
  public static get SENDEMAIL(): string { return 'client-registration/send-email'; }
  public static get ACTIVATECLIENT(): string { return 'client-registration/activate-client'; }
  public static get REGISTRATION_CONFIRMATION(): string { return 'registration/confirmation'; }
  public static get SENDEMAILFORGOT(): string { return 'forgot-password/send-email'; }
  public static get CHANGEFORGOTPASSWORD(): string { return 'forgot-password/'; }
  public static get LOGIN(): string { return 'login'; }
  public static get LOGOUT(): string { return 'api/auth/logout'; }
  public static get PERSON(): string { return 'person'; }
  public static get FACILITY(): string { return 'api/directories/grouped-facilities'; }
  public static get ADD_BOOKING(): string { return 'api/professional-booking/complete-add'; }
  public static get SPECIALITY(): string { return 'api/directories/grouped-expertises'; }
  public static get EXPERTISE_DESC(): string { return 'api/directories/expertises-descriptions'; }
  public static get EXPERTISE_SUB_DESC(): string { return 'api/directories/expertises-subsubdirectory-descriptions'; }
  public static get EXPERTISE_SUB(): string { return 'api/directories/expertises-subdirectory'; }
  public static get EXPERTISE_SUB_SUB(): string { return 'api/directories/expertises-subdirectory-subsubdirectory'; }
  public static get EXPERTISE_GROUPED_EXPERTISE(): string { return 'api/directories/grouped-expertises'; }
  public static get ESTABLISHMENT_TYPES(): string { return 'api/directories/grouped-establishments'; }
  public static get ESTABLISHMENT_TYPE_SUBDIRECTORY(): string { return 'api/directories/establishment-subsubdirectory-descriptions'; }
  public static get DETAIL_ESTABLISHMENT(): string { return 'api/establishment/get-detail'; }
  public static get URL_CREATE_TRAINER(): string { return 'api/professional/complete-add'; }
  public static get DETAIL_USER(): string { return 'api/profiles'; }
  public static get TRAINER(): string { return 'trainer/create-trainer'; }
  public static get TRAINER_LIST(): string { return 'api/professional/get-list-cms'; }
  public static get ALL_STRONGBEE_TRAINER_LIST(): string { return 'api/professional/get-all-strongbee-trainers-list-cms'; }
  public static get TRAINER_DETAIL(): string { return 'api/professional'; }
  public static get CLASSES_LIST(): string { return 'api/places/get-list-cms'; }
  public static get CREATE_CLASSES(): string { return 'api/places/complete-add'; }
  public static get V2_CREATE_CLASSES(): string { return 'api/v2/places/complete-add'; }
  public static get EDIT_CLASSES(): string { return 'api/places/complete-edit'; }
  public static get DETAIL_CLASSES(): string { return 'api/places/get-detail'; }
  public static get V2_DETAIL_CLASSES(): string { return 'api/places/get-detail-v2'; }
  public static get V3_DETAIL_CLASSES(): string { return 'api/v2/places/get-detail'; }
  public static get DETAIL_SINGLE_CLASS(): string { return 'api/places/class/get-detail'; }
  public static get V2_DETAIL_SINGLE_CLASS(): string { return 'api/v2/places/class/get-detail'; }
  public static get EDIT_SINGLE_CLASS(): string { return 'api/places/edit-class-v2'; }
  public static get V2_EDIT_SINGLE_CLASS(): string { return 'api/v2/places/edit-class'; }
  public static get BOOKING_COURT_API(): string { return 'booking-service/api/internal/booking/court/v1'; }
  public static get BOOKING_COURT_DETAIL_API(): string { return 'booking-service/api/internal/booking/court/detail/v1'; }
  public static get BOOKING_COURT_CALCULATE_API(): string { return 'booking-service/api/internal/calculate/court/v1'; }

  public static get QUOTES_LIST(): string { return 'api/quote'; }
  public static get QUOTES_CREATE(): string { return 'api/quote/complete-add'; }
  public static get QUOTES_EDIT(): string { return 'api/quote'; }
  public static get QUOTES_DETAIL(): string { return 'api/quote'; }
  public static get LIST_USER(): string { return 'api/profiles/get-list'; }
  public static get V2_LIST_USER(): string { return 'api/v2/profiles/get-list'; }
  public static get LIST_REGENCY(): string { return 'api/regency/list'; }
  public static get UPDATE_TRAINER(): string { return 'api/professional/complete-edit'; }
  public static get DELETE_CLASS(): string { return 'api/professional/validate-delete-professional-class'; }
  public static get DETAIL_BOOKING(): string {return 'booking-service/api/internal/booking/professional/v1/detail'; }
  public static get CREATE_ADMIN(): string {return 'profiles/create-admin'; }
  public static get EDIT_BOOKING(): string { return 'booking-service/api/internal/booking/professional/v1'; }
  public static get DELETE_BOOKING(): string { return 'booking/delete-booking'; }
  public static get LIST_SHIFT(): string { return 'trainer/get-shift-trainer'; }
  public static get CREATE_BOOKING(): string { return 'api/professional-booking/complete-add'; }
  public static get GET_ADVANCED_LIST(): string { return 'trainer/get-advanced-list'; }
  public static get URL_GMAPS(): string { return 'https://maps.google.com/maps/api/geocode/json'; }
  public static get ADMIN_DETAIL(): string { return 'profiles/get-detail-admin'; }
  public static get UPDATE_ADMIN(): string { return 'profiles/update-admin'; }
  public static get LIST_ADMIN(): string { return 'profiles/get-list-admin'; }
  public static get UPLOAD_IMG(): string { return 'api/image/upload'; }
  public static get UPLOAD_IMG_PHOTO(): string { return 'api/image/upload'; }

  public static get UPDATE_USER(): string { return 'profiles/update-customer'; }
  public static get UPDATE_STATUS_USER(): string { return 'api/profiles/update-status-user'; }
  public static get TOPUP_WALLET_USER(): string { return 'booking-service/api/internal/wallet/topup'; }
  public static get CURRENT_BALANCE_USER(): string { return 'booking-service/api/internal/wallet/check-balance/v2'; }
  public static get HISTORY_WALLET_USER(): string { return 'wallet-service/cms/history-balance'; }
  public static get DEDUCT_WALLET_USER(): string { return 'wallet-service/credit/deduct'; }

  public static get GET_BANK(): string { return 'bank/get-list'; }
  public static get VERIFIKASI_PEMBAYARAN(): string { return 'booking/verification-payment-by-admin'; }
  public static get CREATE_USER(): string { return 'api/registration/user-cms'; }
  public static get CREATE_COVER(): string { return 'cover/create-cover'; }
  public static get LIST_COVER(): string { return 'cover/get-list-cover'; }
  public static get DELETE_COVER(): string { return 'cover/delete-cover'; }
  public static get CHANGE_PASS(): string { return 'api/forgot-password/change-password-cms'; }
  public static get ADD_SPECIALITY(): string { return 'speciality/create-speciality'; }
  public static get DELETE_SPECIALITY(): string { return 'speciality/delete-speciality'; }
  public static get APROVAL_RATING(): string { return 'rating/approval-rating'; }
  // public static get GET_BERANDA(): string { return ""; };
  public static get GET_DETAIL_SHIFT(): string { return 'trainer/get-detail-shift'; }
  public static get ADD_RATING(): string { return 'rating/add-rating'; }
  public static get CREATE_ADS(): string { return 'media/create-media'; }
  public static get CREATE_VOUCHER(): string {return 'voucher/add-voucher'; }
  public static get LIST_VOUCHER(): string {return 'voucher/get-list-voucher'; }
  public static get DETAIL_VOUCHER(): string {return 'voucher/get-detail-voucher'; }
  public static get DELETE_VOUCHER(): string {return 'voucher/delete-voucher'; }
  public static get EDIT_VOUCHER(): string {return 'voucher/edit-voucher'; }
  public static get CHECK_VOUCHER(): string {return 'voucher/check-voucher'; }
  public static get CREATE_ESTABLISH(): string {return 'api/establishment/complete-add-2'; }
  public static get EDIT_ESTABLISH(): string {return 'api/establishment/complete-edit-2'; }
  public static get LIST_ESTABLISHMENT(): string {return 'api/establishment/get-list-cms'; }
  public static get LIST_ESTABLISHMENT_FILTER(): string {return 'api/establishment/get-list'; }
  public static get LIST_BOOKING(): string {return 'booking-service/api/internal/booking/professional/v1'; }
  public static get LIST_BOOKING_CLASS(): string { return 'booking-service/api/internal/booking/class/v1'; }
  public static get LIST_BOOKING_OFFLINE_CLASS(): string { return 'booking-service/cms/booking/class-offline/list/v1'; }
  public static get DETAIL_BOOKING_CLASS(): string { return 'booking-service/api/internal/booking/class/v1/detail'; }
  public static get DETAIL_BOOKING_OFFLINE_CLASS(): string { return 'booking-service/cms/booking/class-offline/detail/v1'; }
  public static get EDIT_BOOKING_CLASS(): string { return 'booking-service/api/internal/booking/class/v1'; }
  public static get UPDATE_BOOKING_STATUS(): string {return 'api/professional-booking/update-status-booking'; }
  public static get LIST_EVENT(): string {return 'api/event/get-list-cms'; }
  public static get LIST_REQUEST_EVENTS(): string {return 'api/event/cms/request-event/list'; }
  public static get LIST_EVENT_DIRECTORIES(): string {return 'api/directories/events'; }
  public static get LIST_EVENT_EXPERTISES(): string { return 'api/directories/event-olahraga'; }
  public static get LIST_CURRENCIES(): string { return 'api/currency'; }
  public static get CREATE_EVENT(): string {return 'api/event/complete-add'; }
  public static get UPDATE_EVENT(): string {return 'api/event/complete-edit'; }
  public static get REQUESTED_EVENT_DETAILS(): string {return 'api/partner-payment-config/cms/event-provider/detail'; }
  public static get REJECT_REQUESTED_EVENT(): string {return 'api/event/cms/request-event/status/update'; }
  public static get ACCEPT_REQUESTED_EVENT(): string {return 'api/partner-payment-config/cms/event-provider/update'; }
  public static get DETAIL_EVENT(): string {return 'api/event/get-detail'; }
  public static get RATING_ESTABLISH(): string {return 'api/establishment-rating/get-list'; }
  public static get RATING_ESTABLISH_EDIT(): string {return 'api/establishment-rating/update-status-rating-review-establishment'; }
  public static get RATING_PROFESSIONAL(): string {return 'api/professional-rating/get-list'; }
  public static get RATING_PROFESSIONAL_EDIT(): string {return 'api/professional-rating/update-status-rating-review-professional'; }
  public static get PAYMENT_LIST(): string {return 'api/payment-method/get-list'; }
  public static get PAYMENT_LIST_NEW(): string {return 'booking-service/api/internal/payment-method/list'; }
  public static get MIDTRANS_WEBVIEW(): string {return 'api/payment/pay'; }
  public static get CREATE_CATEGORY(): string {return 'api/category/complete-add'; }
  public static get LIST_CATEGORY(): string {return 'api/category/list'; }
  public static get CATEGORY(): string {return 'api/category'; }
  public static get EDIT_CATEGORY(): string {return 'api/category/complete-edit'; }
  public static get DETAIL_CATEGORY(): string {return 'api/category/complete-edit'; }
  public static get LIST_HOMECARD(): string {return 'api/v2/home-card/list'; }
  public static get HOMECARD(): string {return 'api/v2/home-card'; }
  public static get CREATE_DIRECTORY(): string {return 'api/directories'; }
  public static get GET_SUB_DIRECTORY(): string {return 'api/directories/specific/sub-directory-list'; }
  public static get GET_SUB_SUB_DIRECTORY(): string {return 'api/directories/specific/sub-sub-directory-list'; }
  public static get LIST_EXPERTISES(): string {return 'api/directories/expertises'; }
  public static get LIST_SUBCATEGORY(): string {return 'api/sub-category/get-list'; }
  public static get CREATE_SUBCATEGORY(): string {return 'api/sub-category/complete-add'; }
  public static get Detail_SUBCATEGORY(): string {return 'api/sub-category'; }
  public static get EDIT_SUBCATEGORY(): string {return 'api/sub-category/complete-edit'; }
  public static get LIST_TAG(): string {return 'api/v2/tags/list'; }
  public static get TAGS(): string {return 'api/v2/tags'; }
  public static get LISTAGSERVICE(): string {return 'api/v2/tags/internal/get-service-tags'; }
  public static get ADDTAGSERVICE(): string {return 'api/v2/tags/internal/add-service-tags'; }
  public static get DEEPLINK_SCHEME(): string { return environment.deeplink_scheme; }

  public static get LIST_PROMO_CODE(): string {return 'promo-service/api/internal/promo/v1'; }
  public static get DETAIL_PROMO_CODE(): string {return 'promo-service/api/internal/promo/v1'; }
  public static get ADD_PROMO_CODE(): string {return 'api/internal/promo/v1'; }
  public static get EDIT_PROMO_CODE(): string {return 'promo-service/api/internal/promo/v1'; }

  public static get LIST_TAG_SERVICE_FILTER(): string {return 'api/v2/tags/internal/get-service-filter-tags'; }
  public static get ADD_TAG_SERVICE_FILTER(): string {return 'api/v2/tags/internal/add-service-filter-tags'; }

  public static get LIST_RATING_PROFESSIONAL() { return 'api/professional-rating/get-list'; }
  public static get LIST_RATING_CLASS() { return 'api/classes-rating/get-list'; }
  public static get LIST_RATING_ESTABLISHMENT() { return 'api/establishment-rating/get-list'; }
  public static get UPDATE_RATING_ESTABLISHMENT() { return 'api/establishment-rating/update-status-rating-review-establishment'; }
  public static get UPDATE_RATING_CLASS() { return 'api/classes-rating/update-status-rating-review-class'; }
  public static get UPDATE_RATING_PROFESSIONAL() { return 'api/professional-rating/update-status-rating-review-professional'; }

  public static get LIST_BOOKING_EVENT() { return 'booking-service/api/internal/booking/event-ticket-list/v1'; }
  public static get DETAIL_BOOKING_EVENT() { return 'booking-service/api/internal/booking/event-ticket-detail/v1/detail'; }
  public static get EDIT_BOOKING_EVENT() { return 'booking-service/api/internal/booking/event-ticket-edit/v1'; }

  public static get LIST_PUSH_MESSAGE() { return 'push-notif/api/internal/get-push-notif'; }
  public static get ADD_PUSH_MESSAGE() { return 'push-notif/api/internal/create-push-notif'; }
  public static get EDIT_PUSH_MESSAGE() { return 'push-notif/api/internal/edit-push-notif'; }
  public static get DETAIL_PUSH_MESSAGE() { return 'push-notif/api/internal/get-detail-push-notif'; }
  public static get BLAST_PUSH_MESSAGE() { return 'push-notif/api/internal/sendall-push-notif'; }

  public static get ADD_TRAINER_ACCOUNT() { return 'trainer/account/register'; }
  public static get TRAINER_ACCOUNT() { return 'trainer/account'; }
  public static get TRAINER_SEND_EMAIL() { return 'trainer/email/send_trainer_registered'; }
  public static get TRAINER_ACCOUNT_DETAIL() { return 'trainer/account/detail'; }

  public static get LIST_CASHBACK(): string {return 'promo-service/api/internal/cashback/v1'; }
  public static get ADD_CASHBACK(): string {return 'promo-service/api/internal/cashback/v1'; }
  public static get DETAIL_CASHBACK(): string {return 'promo-service/api/internal/cashback/v1'; }
  public static get EDIT_CASHBACK(): string {return 'promo-service/api/internal/cashback/v1'; }

  public static get LIST_BPASS(): string { return 'product-service/cms/product/list'; }
  public static get DETAIL_BPASS(): string { return 'product-service/cms/product/detail'; }
  public static get ADD_BPASS(): string { return 'product-service/cms/product/create'; }
  public static get EDIT_BPASS(): string { return 'product-service/cms/product/update'; }
  public static get LIST_BOOKING_BPASS(): string { return 'booking-service/api/internal/booking/bpass/list'; }
  public static get DETAIL_BOOKING_BPASS(): string { return 'booking-service/api/internal/booking/bpass/detail'; }
  public static get EDIT_BOOKING_BPASS(): string { return 'booking-service/api/internal/booking/bpass/edit'; }

  public static get LIST_REWARDS_STRONGWHEEL(): string { return 'strongwheel/cms/reward/list'; }
  public static get DETAIL_REWARD_STRONGWHEEL(): string { return 'strongwheel/cms/display-reward'; }
  public static get UPDATE_REWARD_STRONGWHEEL(): string { return 'strongwheel/cms/reward/update'; }
  public static get CREATE_REWARD_STRONGWHEEL(): string { return 'strongwheel/cms/reward/add'; }

  public static get UPDATE_ZOOMLINK_SENT_CLASS(): string { return 'booking-service/internal/booking/class/zoom-link-sent'; }
  public static get UPDATE_ZOOMLINK_SENT_TRAINER(): string { return 'booking-service/internal/booking/professionsl/zoom-link-sent'; }
  public static get UPDATE_ZOOMLINK_SENT_EVENT(): string { return 'booking-service/internal/booking/event/zoom-link-sent'; }

  public static get LIST_QUESTIONS(): string { return 'api/questionnaire/cms/search'; }
  public static get DETAIL_QUESTION(): string { return 'api/questionnaire/cms/get-detail'; }
  public static get UPDATE_QUESTION(): string { return 'api/questionnaire/cms/update'; }
  public static get SEARCH_RELATED_ANSWER(): string { return 'api/questionnaire/cms/search-related-answer'; }
  public static get UPDATE_ANSWER(): string { return 'api/questionnaire/cms/update-answer'; }
  public static get DELETE_ANSWER(): string { return 'api/questionnaire/cms/delete-answer'; }
  public static get ADD_ANSWER(): string { return 'api/questionnaire/cms/add-answer'; }

  public static get LIST_ZOOM_CLASS(): string { return 'booking-service/internal/zoom/meeting/class/list'; }
  public static get DETAIL_ZOOM_CLASS(): string { return 'booking-service/internal/zoom/meeting/class/detail'; }
  public static get UPDATE_ZOOM_CLASS(): string { return 'booking-service/internal/zoom/meeting/class/update'; }
  public static get RESEND_ZOOM_CLASS(): string { return 'booking-service/internal/zoom/meeting/class/resend'; }

  public static get LIST_ZOOM_PROFESSIONAL(): string { return 'booking-service/internal/zoom/meeting/professional/list'; }
  public static get DETAIL_ZOOM_PROFESSIONAL(): string { return 'booking-service/internal/zoom/meeting/professional/detail'; }
  public static get UPDATE_ZOOM_PROFESSIONAL(): string { return 'booking-service/internal/zoom/meeting/professional/update'; }
  public static get RESEND_ZOOM_PROFESSIONAL(): string { return 'booking-service/internal/zoom/meeting/professional/resend'; }

  public static get ADD_ATTENDANCE_CHECKIN(): string { return 'booking-service/api/internal/attendance/v1/class/checkin'; }

  public static get GET_SETTING_REFERAL(): string { return 'referral/cms/view-setting'; }
  public static get UDPATE_REFERAL(): string { return 'referral/cms/update-setting'; }
  public static get HISTORY_REFERAL_SETTING(): string { return 'referral/cms/setting-history'; }
  public static get GET_LIST_REFERRAL_CODE(): string { return 'referral/cms/list-referral-code'; }
  public static get GET_LIST_JOINED_USERS(): string { return 'referral/cms/list-referral-usage-by-code'; }
  public static get GET_LIST_REFERERS(): string { return 'referral/cms/list-referral-usage'; }

  public static get CREATE_NEW_ADMIN(): string {return 'api/account/cms/add-user'; }
  public static get LIST_NEW_ADMIN(): string {return 'api/account/cms/list-user'; }
  public static get SEND_ACTIVATION_ADMIN(): string {return 'api/account/cms/send-activation-email'; }
  public static get CHECK_TOKEN_ACTIVATION(): string {return 'api/account/cms/is-activation-code-valid'; }
  public static get SUBMIT_ACTIVATION(): string {return 'api/account/cms/activate-user'; }
  public static get SENDEMAILFORGOT_NEW(): string { return 'api/account/cms/request-reset-password'; }
  public static get RESET_PASSWORD(): string { return 'api/account/cms/reset-password'; }
  public static get GET_DETAIL_ADMIN(): string { return 'api/account/cms/get-user-detail'; }
  public static get UPDATE_DATA_ADMIN(): string { return 'api/account/cms/edit-user'; }
  public static get DELETE_ADMIN(): string { return 'api/account/cms/delete-user'; }

  public static get GET_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/list'; }
  public static get GET_FAVOURITE_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/list-favorite'; }
  public static get ADD_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/create'; }
  public static get GET_ACTIVE_BOOKINGS(): string {return 'booking-service/cms/booking/class/active-count'; }
  public static get GET_SCHEDULE_ATTENDANTS_LIST(): string {return 'booking-service/api/internal/schedule/attendant/list'; }
  public static get DELETE_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/remove'; }
  public static get UPDATE_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/edit'; }
  public static get UPDATE_STATUS_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/switch-on-off'; }
  public static get UPDATE_FAVOURITE_STATUS_SCHEDULE_CLASS(): string { return 'api/class-schedule/cms/set-favorite'; }
  public static get UPDATE_RESCHEDULE_CLASS(): string { return 'api/class-schedule/cms/reschedule'; }
  public static get SET_TO_FULL_BOOK(): string { return 'api/class-schedule/cms/set-full-book'; }

  public static get GET_SCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/list'; }
  public static get GET_ACTIVE_BOOKINGS_TRAINER(): string {return 'booking-service/cms/booking/trainer/active-count'; }
  public static get ADD_SCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/create'; }
  public static get DELETE_SCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/remove'; }
  public static get UPDATE_SCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/edit'; }
  public static get UPDATE_RESCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/reschedule'; }
  public static get UPDATE_STATUS_SCHEDULE_TRAINER(): string { return 'api/trainer-schedule/cms/switch-on-off'; }

  // public static get GET_TRANSACTIONS_ESTABLISHMENT(): string { return 'booking-service/reporting-payment/cms/establishment/list'; }
  public static get GET_TRANSACTIONS_ESTABLISHMENT(): string { return 'booking-service/reporting-payment/cms/v2/establishment/list'; }
  public static get UPDATE_PAYMENT_STATUS(): string { return 'booking-service/reporting-payment/cms/update-payment-status'; }
  // public static get GET_TRANSACTIONS_TRAINER(): string { return 'booking-service/reporting-payment/cms/trainer/list'; }
  public static get GET_TRANSACTIONS_TRAINER(): string { return 'booking-service/reporting-payment/cms/v2/trainer/list'; }
  public static get GET_LIST_PAYMENT_SETTING(): string { return 'api/partner-payment-config/cms/list'; }
  public static get UPDATE_SETTING_PAYMENT(): string { return 'api/partner-payment-config/cms/edit'; }
  public static get UPDATE_SETTING_PAYMENT_V2(): string { return 'api/partner-payment-config/cms/edit/v2'; }
  public static get GET_TRANSACTIONS_EVENT(): string { return 'booking-service/reporting-payment/cms/event/list'; }
  public static get GET_DETAIL_PAYMENT_SETTING(): string { return 'api/partner-payment-config/cms/detail'; }
  public static get GET_LIST_BANKS(): string { return 'api/partner-payment-config/banks/list'; }
  public static get VALIDATE_BANK_ACCOUNT(): string { return 'api/partner-payment-config/banks/validate'; }
  public static get CREATE_BENEFICIARY(): string { return 'api/partner-payment-config/beneficiary/add'; }
  public static get UPDATE_BENEFICIARY(): string { return 'api/partner-payment-config/beneficiary/update'; }
  public static get GET_HISTORY_PAYOUT(): string { return 'booking-service/api/v1/cms/payout/history'; }
  public static get GET_LIST_APPROVAL_BENEFICIARY(): string { return 'api/partner-payment-config/cms/beneficiary/list/approval'; }
  public static get ACCEPT_REJECT_BENEFICIARY(): string { return 'api/partner-payment-config/cms/beneficiary/accept-reject'; }
  public static get UPDATE_BENEFICIARY_V2(): string { return 'api/partner-payment-config/cms/beneficiary/update/v2'; }
  public static get CREATE_BENEFICIARY_NEW(): string { return 'api/partner-payment-config/cms/beneficiary/create'; }
  public static get GET_HISTORY_CHANGE_LOGS(): string { return 'api/partner-payment-config/cms/change-logs/list'; }
  public static get GET_WITHDRAW_BALANCE(): string { return 'booking-service/api/v1/cms/payout/balance'; }
  public static get GET_PENDING_PAYOUTS(): string { return 'booking-service/api/v1/cms/payout/pending/list'; }
  public static get RESEND_PENDING_PAYOUTS(): string { return 'booking-service/api/v1/cms/payout/pending/resend'; }

  public static get PROVIDER_ROLE_LIST(): string { return 'api/role/cms/list-provider-role'; }
  public static get PROVIDER_LIST(): string { return 'api/account/cms/provider/list-user'; }
  public static get PROVIDER_ACCOUNT_REGISTER(): string { return 'api/account/cms/provider/register-user'; }
  public static get PROVIDER_ACCOUNT_UPDATE(): string { return 'api/account/cms/edit-provider'; }
  public static get PROVIDER_SEND_ACTIVATION_EMAIL(): string { return 'api/account/cms/send-activation-email'; }
  public static get PROVIDER_DETAIL(): string { return 'api/account/cms/get-provider-detail'; }

  public static get PROVIDER_BANNER_LIST(): string { return 'api/banner-provider/cms/list'; }
  public static get PROVIDER_BANNER_DETAIL(): string { return 'api/banner-provider/cms/detail'; }
  public static get PROVIDER_BANNER_ADD(): string { return 'api/banner-provider/cms/create'; }
  public static get PROVIDER_BANNER_UPDATE(): string { return 'api/banner-provider/cms/edit'; }

  public static get GET_BOOKING_TRAINER_PARTICIPANT(): string { return 'booking-service/cms/booking-trainer/list-participant'; }
  public static get UPDATE_BOOKING_TRAINER_PARTICIPANT(): string { return 'booking-service/cms/booking-trainer/update-participant'; }

  public static get GET_LIST_PENALTY_CONFIG(): string { return 'booking-service/api/cms/penalty-config/list'}
  public static get GET_DETAIL_PENALTY_CONFIG(): string { return 'booking-service/api/cms/penalty-config/detail'}
  public static get UPDATE_PENALTY_CONFIG(): string { return 'booking-service/api/cms/penalty-config/update'}
  public static get GET_LOGS_PENALTY_CONFIG(): string { return 'booking-service/api/cms/penalty-config/logs'}

  public static get LIST_REPORT_BOOKING_SUMMARY(): string { return 'booking-service/get-summary-report-total-completed-booking-and-revenue'; }

  public static get GET_BOOKING_EVENT_CHECKIN(): string { return 'booking-service/api/internal/booking-event/check-in/history'; }

  // New API to get list for Directory, Sub Directory and Sub Sub Directory for Expertise Module
  public static get EXPERTISE_NEW_LIST_DIRECTORY(): string { return 'api/directories/grouped-directories'; }
  public static get EXPERTISE_NEW_LIST_SUB_DIRECTORY(): string { return 'api/directories/grouped-sub-directories'; }
  public static get EXPERTISE_NEW_LIST_SUB_SUB_DIRECTORY(): string { return 'api/directories/grouped-sub-sub-directories'; }


  // New API for reschedule feature
  public static get GET_RESCHEDULE_CLASS(): string { return 'api/class-schedule/cms/list/reschedule'; }
  public static get RESCHEDULE_CLASS(): string { return 'booking-service/api/cms/booking/class/reschedule'; }
}

